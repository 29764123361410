import Navigation   from "./navigation";
import * as React   from "react";

import {
	CognitoUserPool,
	CognitoUser,
	AuthenticationDetails,
} from 'amazon-cognito-identity-js';

import '@fontsource/source-sans-pro/400.css'
import '@fontsource/source-sans-pro/700.css'
import '../styles/layout.scss';

import { useEffect, useState } from "react";
import config                  from "../config";

const processLogin = (userPool, email, password) => {
	return new Promise(
		(resolve, reject) => {
			const authenticationDetails = new AuthenticationDetails({
				Username: email,
				Password: password,
			});

			const cognitoUser = new CognitoUser({
				Username: email,
				Pool: userPool,
			});

			const callbacks = {
				onSuccess: ( result ) => {
					console.log(result.getAccessToken().getJwtToken());
					resolve();
				},
				onFailure: (err) => {
					console.error(err.message || JSON.stringify(err));
					reject();
				},
				mfaRequired: () => {
					const mfaCode = prompt('MFA code')
					cognitoUser.sendMFACode(mfaCode, callbacks);
				},
				newPasswordRequired: (userAttributes) => {
					const newPassword = prompt('New password');

					console.log(userAttributes);

					delete userAttributes.email_verified;
					delete userAttributes.phone_number_verified;

					if(newPassword) {
						cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, callbacks);
					}

				}
			};

			cognitoUser.authenticateUser(authenticationDetails, callbacks);
		}
	);
}

const Layout = ({ title, children, onAuthenticatedChange, isSlim }) => {
	const [ isLoginHidden, setIsLoginHidden ] = useState(true);
	const [ email, setEmail ] = useState('');
	const [ password, setPassword ] = useState('');
	const [ isAuthenticated, setIsAuthenticated ] = useState(false);
	const [ pipelineStatus, setPipelineStatus ] = useState(null);

	const [ userPool ] = useState(new CognitoUserPool({
		UserPoolId: config.UserPoolId,
		ClientId: config.ClientId,
	}));

	onAuthenticatedChange = onAuthenticatedChange || (() => {});

	const login = async (email, password) => {
		try {
			await processLogin(userPool, email, password);
			setIsAuthenticated(true);
			onAuthenticatedChange(true);
		} catch (e) {
			console.error(e);
		} finally {
			setPassword('');
		}
	}

	const logout = () => {
		userPool.getCurrentUser().signOut(() => {
			setIsAuthenticated(false);
			onAuthenticatedChange(false);
		})
	}

	const commit = async () => {
		setPipelineStatus("InProgress");

		const token = await new Promise((resolve, reject) => {
			userPool.getCurrentUser().getSession((error, session) => {
				if(error) {
					reject(error);
				} else {
					resolve(session.getIdToken().getJwtToken());
				}
			})
		});

		console.log(token);

		const response = await fetch(`${ config.apiUrl }/commit`, {
			method: 'POST',
			headers: {
				'Authorization': token
			}
		})

		const { pipelineExecutionId } = await response.json();

		const loop = async () => {
			const r = await fetch(`${ config.apiUrl }/job/${ pipelineExecutionId }`, {
				method: 'GET',
				headers: {
					'Authorization': token
				}
			});

			const { pipelineStatus } = r.json()

			setPipelineStatus("pipelineStatus")

			if (!(["Succeeded", "Stopped", "Superseded"].find(el => el === pipelineStatus))) {
				setTimeout(() => loop().catch(console.error), 5000);
			}
		};

		loop().catch(console.error);
	}

	useEffect(() => {
		const isUser = !!userPool.getCurrentUser();
		setIsAuthenticated(isUser);
		onAuthenticatedChange(isUser);
	}, [userPool, setIsAuthenticated, onAuthenticatedChange])

	return (
		<div className={'page'}>
			<title>{title}</title>
			<Navigation>
				<span><b>Andrzej Wojnar</b> © <span onDoubleClick={() => setIsLoginHidden(false)}>Wszystkie prawa zastrzeżone</span></span>
			</Navigation>
			<div className={`scroll ${isSlim ? 'slim' : ''}`}>
				{
					isAuthenticated ?
					(
						<div className={'login-status'}>
							<span>CMS aktywny</span>
							{ pipelineStatus === "InProgress" ? (<span>Aktualizowanie...</span>) : (<button onClick={() => commit()}>Publikuj zmiany</button>) }
							<button onClick={() => logout()}>Wyloguj</button>
						</div>

					) : (
						isLoginHidden ? (<></>) : (<form className={'login-status'} onSubmit={ (event) => { event.preventDefault(); login(email, password); } }>
							<span>Logowanie CMS</span>
							<input placeholder="email" name="email" type="text" value={ email } onChange={ (event => setEmail(event.target.value)) }/>
							<input placeholder="hasło" name="password" type="password" value={ password } onChange={ (event => setPassword(event.target.value)) }/>
							<button onClick={ (event) => { event.preventDefault(); login(email, password); } }>Zaloguj</button>
						</form>)
					)
				}
				{children}
				{isSlim ? null : <p style={{fontSize: 10, marginTop: 60, textAlign: "center"}}>Wszelkie zdjęcia publikowane na tej stronie są moją własnością. Niedozwolone jest ich kopiowanie, publikowanie i wykorzystywanie na jakichkolwiek polach eksplotacji bez mojej zgody.</p>}
			</div>
			<div id={'modal-root'}/>
		</div>
	)
}

export default Layout;
