import * as React                        from "react"
import { graphql, Link, useStaticQuery } from "gatsby";
import { useLocation }                   from '@reach/router';

import { RiCloseLine } from 'react-icons/ri';

import { GiHamburgerMenu } from 'react-icons/gi';
import { GatsbyImage } from "gatsby-plugin-image";

import '../styles/navigation.scss';
import { useState }        from "react";

const Navigation = ({children}) => {
	const location = useLocation();

	const [ isNavHidden, setIsNavHidden ] = useState(true);

	const data = useStaticQuery(graphql`query AllGalleries{
			allS3SourcedGallery {
				nodes {
					GalleryName
					Order
				}
			}
			allFile(filter: {name: {eq: "aw_black"}}) {
				nodes {
				  	childImageSharp {
						gatsbyImageData
				  	}
				}
		  	}
	}`);

	const logo = data.allFile.nodes[0].childImageSharp.gatsbyImageData;

	return (
		<>
			<div className={'top-bar'}>
				<button className={'nav-action'} onClick={() => {setIsNavHidden(false)}} ><GiHamburgerMenu/></button>
				<div className={'logo'}>
					<GatsbyImage  image={logo} alt={'Logo (andzrejwojnar.pl)'} />
				</div>
			</div>
			<nav className={isNavHidden ? 'nav hidden' : 'nav'}>
				<button className={'close nav-action'} onClick={() => {setIsNavHidden(true)}} ><RiCloseLine/></button>
				<div className={'logo-box'}>
					<GatsbyImage image={logo} alt={'Logo (andzrejwojnar.pl)'} />
				</div>
				<Link className={'nav-link'} to="/">
					Start
				</Link>
				<Link className={'nav-link'} to="/gallery">
					Albumy
				</Link>
				<ul className={location.pathname.startsWith('/gallery') ? 'nav-sub-list' : 'nav-sub-list hidden'}>
					{data.allS3SourcedGallery.nodes.sort((a, b) => {
						return b.Order - a.Order;
					}).map(el => (
						<Link key={el.GalleryName} className={'nav-sub-link'} to={`/gallery/${el.GalleryName}`}>{ el.GalleryName }</Link>
					))}
				</ul>
				<Link className={'nav-link'} to="/about">
					O mnie
				</Link>
				<Link className={'nav-link'} to="/contact">
					Kontakt
				</Link>
				<div className={'spacer'} />
				<footer>
					{children}
					<span className={'small'}>Website made by <a href={'https://jakubzelezik.pl'} target={'_blank'}>Jakub Żelezik</a></span>
				</footer>
			</nav>
		</>
	)
};

export default Navigation;
